svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
    fill: currentColor;
}

input {
    border-color: var(--color-border);
}

hr {
    margin: 4px 0 0;
    border-color: var(--color-border);
}

li, ul {
    list-style: none;
}

button {
    background: transparent;
    border: 0;
    cursor: pointer;

    &:focus, &:active {
        outline: none;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration, select {
    -webkit-appearance: none;
}

