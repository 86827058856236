.vc-highlight, .vc-am-pm .active {
    background: var(--color-tertiary-100) !important;
}

.vc-time-day, .vc-time-month {
    color: var(--color-tertiary-100) !important;
}

.vc-weekday {
    color: var(--color-grey-500);
    font-size: 14px;
}

.pattern {
    .vc-highlight, .vc-am-pm .active {
        background: var(--color-secondary-100) !important;
    }

    .vc-time-day, .vc-time-month {
        color: var(--color-secondary-100) !important;
    }
}

.separator-dashed {
    border: 1px dashed var(--color-grey-300);
}

.ant-calendar-panel {
    .ant-calendar-today .ant-calendar-date {
        border-color: var(--color-tertiary-100);
        color: var(--color-tertiary-100);
    }

    .ant-calendar-selected-day .ant-calendar-date {
        background: var(--color-tertiary-100);
        border-color: var(--color-tertiary-100);
        color: #fff;
    }

    .ant-calendar-date:active {
        background: unset;
        color: unset;
    }

    .ant-calendar-disabled-cell {
        .ant-calendar-date, .ant-calendar-date:hover {
            background: var(--color-pink-200);
        }

        .ant-calendar-date:before {
            display: none;
        }

        &.ant-calendar-today .ant-calendar-date {
            border-color: var(--color-pink-300);
            color: var(--color-pink-500);
        }
    }
}


.ant-calendar-picker-input, .ant-calendar-picker-input.ant-input {
    cursor: pointer;
    border: 0;
    padding: 3px 0;

    &:focus {
        outline: none
    }
}

.ant-calendar-picker:hover .anticon-close-circle {
    display: none !important;
}
