.btn--outline {
    border: 1px solid var(--color-blue-400);
    color: var(--color-blue-400);

    &:focus {
        border: 1px solid var(--color-blue-400);
    }

}

.btn-link {
    font-weight: normal;
    color: var(--color-tertiary-100);

    &:hover {
        color: var(--color-tertiary-100);
    }

    &--hover {
        color: inherit;
    }
}


.pattern {
    .btn-link {
        color: var(--color-secondary-100);

        &:hover {
            color: var(--color-secondary-100);
        }
    }
}

.btn-action {
    display: inline-flex;
    width: 28px;
    height: 28px;
    border: 1px solid var(--color-grey-500);
    border-radius: 4px;

    @extend .transition-3s;

    svg {
        min-width: auto;
        width: 14px;
        height: 14px;
        margin: auto;
    }

    &--pdf svg {
        height: 14px;
    }

    //&:hover {
    //    opacity: .8;
    //}

    &--success {
        border: 1px solid var(--color-green-700);
        color: var(--color-green-700);

        &:hover {
            border: 1px solid var(--color-green-800);
            color: var(--color-green-800);
        }
    }

    &--fail {
        border: 1px solid var(--color-red-500);
        color: var(--color-red-500);

        &:hover {
            border: 1px solid var(--color-red-600);
            color: var(--color-red-600);
        }
    }

    &--primary {
        color: var(--color-tertiary-100);
        border-color: var(--color-tertiary-100);
    }
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.ic-close {
    width: 32px;
    height: 32px;
    opacity: .8;
    cursor: pointer;
    position: relative;

    &:hover {
        opacity: 1;
    }

    &:before, &:after {
        position: absolute;
        left: 15px;
        top: 6px;
        content: ' ';
        height: 22px;
        width: 2px;
        background-color: var(--color-grey-500);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.ic-close--modal {
    position: absolute;
    top: 12px;
    right: 12px;

    @include respond(sm) {
        top: 4px;
        right: 8px;
    }
}

.btn-wrap {
    display: flex;
    align-items: center;
    gap: 16px;

    @include respond(sm) {
        display: grid;
        gap: 24px;
    }
}

.form-btn-wrap {
    a, button {
        padding: 10px 40px;

        @include respond(sm) {
            padding: 8px 32px;
        }
    }
}

