// 1. form header
// 2. custom radio button - .radio-btn-group
// 3. custom checkbox - .custom-checkbox
// 4. custom file input - .file__input
//

.form-header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-pink-200);

    svg {
        margin-right: 12px;
        width: 30px;
        height: 30px;
    }
}

.pattern .form-header{
    border-bottom: 1px solid var(--color-blue-600);
}

.pattern .form-header {
    border-color: var(--color-blue-200);
}

.radio-btn-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
        cursor: pointer;
        position: relative;
        display: flex;
        color: #bbb;
        transition: all .1s;

        &:before, &:after {
            border-radius: 50%;
            content: " ";
            transition: all .1s;
        }
    }

    label:before {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 8px;
        min-width: 16px;
        height: 16px;
        border: 2px solid #ccc;
        background-color: transparent;
    }

    input[type="radio"] {
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked + label, &:hover + label {
            color: var(--color-grey-900);
        }

        &:checked + label:before, &:hover + label:before {
            border-color: var(--color-secondary-100);
        }

        &:checked + label:after {
            width: 8px;
            height: 8px;
            position: absolute;
            top: 6px;
            left: 4px;
            background: var(--color-secondary-100);
        }
    }

    &--secondary {
        input[type="radio"] {
            &:checked + label:before, &:hover + label:before {
                border-color: #FF59AE;
            }

            &:checked + label:after {
                background: #FF59AE;
            }

        }
    }
}

.custom-checkbox {
    display: flex;
    align-items: center;

    input {
        opacity: 0;
        height: 0;
        width: 0;
    }

    label {
        position: relative;
        cursor: pointer;
        color: var(--color-grey-400);
        line-height: 0;
    }

    input:checked + label:before {
        border-color: var(--color-tertiary-100);
        background: var(--color-tertiary-100);
    }

    label:hover:before {
        border-color: var(--color-tertiary-100);
    }


    label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #ccc;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 8px;
        border-radius: 4px;
        @include respond(sm) {
            width: 18px;
            height: 18px;
        }
    }

    &--single label:before {
        border-color: var(--color-tertiary-100);
    }

    input:checked + label:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 1px 1px 0;
        transform: rotate(
                43deg
        );
        @include respond(sm) {
            top: 2px;
            left: 7px;
        }
    }

    input:indeterminate {

        & + label:before {
            border-color: var(--color-tertiary-100);
            background: var(--color-tertiary-100);
        }

        & + label:after {
            content: '';
            position: absolute;
            top: 8px;
            left: 4px;
            width: 8px;
            height: 1px;
            background: #fff;
        }

    }

    &:not(.custom-checkbox--single,.custom-checkbox--secondary) {
        input:checked + label, label:hover {
            color: var(--color-grey-900);
        }
    }

    &--secondary {
        input:checked + label, label:hover {
            color: var(--color-grey-900)
        }

        input:checked + label:before {
            border-color: var(--color-secondary-100);
            background: var(--color-secondary-100);
        }

        label:hover:before {
            border-color: var(--color-secondary-100);
        }


        input:indeterminate {

            & + label:before {
                border-color: var(--color-secondary-100);
                background: var(--color-secondary-100);
            }

        }
    }
}

.file__input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &-wrap {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
        padding: 6px;
        border: 1px solid var(--color-border);
    }

    &-wrap:hover .file__input-trigger {
        opacity: .8;
    }

    &-trigger {
        background: var(--color-secondary-200);
        color: #fff;
        padding: 10px 8px;
        border-radius: 8px;
        font-size: 12px;
        pointer-events: none;
    }

    &--secondary .file__input-trigger {
        background: #FF59AE;
    }

}

.file{
    &__list{
        display: flex;
        gap :12px;
        background: var(--color-grey-200);
        border-radius: 8px;
        padding: 12px;
        margin-top: 12px;
    }

    &__item {
        border-radius: 4px;
        overflow: hidden;
        width: 80px;
        height: 80px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, .4);
            transition: .3s all;
        }

        &:hover:before{
            background: rgba(0, 0, 0, .2);
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__delete{
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 50%;
        background: #f53849;
        color: #fff;
        padding: 2px;
        cursor: pointer;
    }

    &__name{
        font-size: 10px;
    }
}

.search-req {
    overflow: visible;
    max-width: 540px;
    box-shadow: 2px 2px 4px rgba(102, 36, 70, 0.1);

    .form-control {
        border: 0;
    }

    .ant-select-selection-placeholder {
        padding: 8px 16px !important;
    }
}

.search-form input {
    padding-top: 9px;
    padding-bottom: 9px;
}

.form-icon {
    position: relative;
    color: #aaa;

    svg {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        z-index: 4;
        pointer-events: none;
    }

    &-no-border {
        color: var(--color-grey-300);

        svg {
            left: 2px;
            right: auto;
            fill: var(--color-grey-400);
        }
    }

    &-no-border .form-control {
        padding-left: 30px;
        font-size: 16px;
    }

    .form-control {
        cursor: pointer;
        caret-color: transparent;
    }
}

.search-result {
    z-index: 1028;

    &-list {
        max-height: 300px;
        overflow-y: auto;
    }

    li {
        border-top: 1px solid var(--color-grey-200);
    }

    .autocomplete-result.is-active {
        background-color: #f2f2f2;
    }

}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #eee #ccc;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 50px;
    }
}


.custom-scrollbar--secondary {
    scrollbar-width: thin;
    scrollbar-color: #eee var(--color-secondary-100);

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-secondary-100);
        border-radius: 50px;
    }
}

.multiselect-dropdown {
    overflow-y: auto !important;
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey-100) var(--color-grey-300);
    box-shadow: 8px 8px 18px 0 #6624461A;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: var(--color-grey-100);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey-300);
        border-radius: 5px;
    }
}

.test-work-order .multiselect-dropdown {
    min-width: 140px;
    right: 0;
    left: auto;
}


button:disabled {
    pointer-events: none;
    opacity: .6;
}


.form-control {
    border: 1px solid var(--color-grey-300);
    border-radius: 8px;
    padding: 12px 16px;
    width: 100%;

    &:focus, &:focus-visible {
        outline: none;
    }
}

.pattern {
    .form-control:focus {
        border-color: var(--color-secondary-100);
    }
}

.label {
    display: block;
    color: var(--color-grey-500);
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 700;
    text-transform: capitalize;
}

.form-control-password {
    height: 46px;
    font-size: 24px;
    font-family: caption, sans-serif;
}

.card--empty {
    min-height: 348px;
    height: calc(100vh - 332px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-error {
    input[type="text"], input[type="email"], .file__input-wrap, .form-control {
        border-color: var(--color-red-500);
    }

    select, .multiselect {
        border-color: var(--color-red-500);
    }
}

.label-error {
    display: block;
    color: var(--color-red-500);
    font-weight: 500;
    font-size: 14px;
    margin-top: 6px;
}

.input-error .no-error .multiselect {
    border: 1px solid var(--color-grey-300) !important;
}


.form-control--suggestion .ant-input {
    @extend .form-control
}


.form-control:disabled, .multiselect.is-disabled {
    background: var(--color-grey-100) !important;
}


.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 0 !important;
}

.ant-select-dropdown {
    box-shadow: 8px 8px 18px 0 #6624461A !important;
    z-index: 1020 !important;

    .ant-select-item-option-selected {
        background: var(--color-tertiary-100) !important;
        color: #fff !important;
        font-weight: normal !important;
    }
}

.ant-select-item {
    color: var(--color-grey-900);
}

.ant-select-item-active:not(.ant-select-item-option-selected) {
    background: var(--color-grey-100) !important;
}

