.tab {
    &__list {
        display: flex;
        border-bottom: 1px solid var(--color-grey-200);
    }

    &__item {
        cursor: pointer;
        padding: 16px 24px;
        letter-spacing: 1px;
        font-size: 14px;
        position: relative;
        color: var(--color-grey-500);
        transition: .3s all;

        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            height: 3px;
            width: 100%;
            background: var(--color-tertiary-100);
            visibility: hidden;
            opacity: 0;
            transform: translateY(-50%);
            transition: .3s all;
        }

        &.is--active {
            color: var(--color-tertiary-100);
            position: relative;
            box-shadow: none;

            &:before {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__content {
        padding: 32px;
    }

    &__content-item {
        display: none;

        &.is--active {
            display: block;
            opacity: 1;

        }
    }
}

.pattern {

    .tab {
        &__item:before {
            background: var(--color-secondary-100);
        }

        &__item.is--active {
            color: var(--color-secondary-100);
        }
    }
}
