.alert-success {
    background: var(--color-green-100);
}

.ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: fixed;
    bottom: 16px;
    right: 32px;
    z-index: 1010;
    pointer-events: none;

    > span {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
}

.ant-message-notice {
    display: inline-flex;
    animation: fadeIn .3s forwards;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
    animation: fadeOut .3s forwards;
}

.ant-message-notice-content {
    border-radius: 8px;
    padding: 0;
    display: flex;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: all;
    overflow: hidden;
}

.ant-message-success {
    color: var(--color-green-700);
    background: var(--color-green-100);
}

.ant-message-error {
    color: var(--color-red-600);
    background: var(--color-red-100);
}

.ant-message-warning {
    color: #faad14;
    background: var(--color-yellow-100);
}

.ant-message-info {
    color: #1890ff;
    background: #dbedff;
}

.ant-message .anticon {
    margin-right: 8px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
    overflow: hidden;
}

.ant-message-content {
    box-shadow: 4px 4px 40px rgba(95, 127, 178, 0.2);
}

.ant-message-custom-content {
    padding: 12px 16px;
    display: inline-flex;
    align-items: center;
}
