.card {
    padding: 40px;
    background: #FFFFFF;
    //box-shadow: 8px 8px 40px rgba(95, 127, 178, 0.2);
    box-shadow: 0 8px 80px -40px #261E5B80;
    border-radius: 8px;

    @include respond(sm) {
        padding: 24px;
    }
}

.card--large {
    box-shadow: 0 8px 80px -40px #261E5B80;
    background: #FFFFFF;
    padding: 64px;
    border-radius: 16px;
    overflow: hidden;

    @include respond(xxl) {
        padding: 40px;
    }

    @include respond(sm) {
        padding: 24px;
    }
}
