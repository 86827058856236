
.dropdown {
    box-shadow: 8px 8px 30px 0 #6624461A;
    background-color: #fff;
    border-radius: 8px;
    min-width: 216px;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 9;
    display: none;

    &__trigger {
        cursor: pointer;
    }

    li a {
        display: block;
        padding: 16px 24px;
    }

    li {
        &:not(:first-child) {
            border-top: 1px solid #F8F6F7;
        }
    }

    &__menu {
        position: relative;
    }

    &--show.dropdown {
        display: block;
    }
}


.filter-dropdown {
    .dropdown {
        width: 414px;
        font-size: 12px;
        box-shadow: 8px 8px 40px rgba(95, 127, 178, 0.2);
    }
}

.pattern {
    .multiselect {

        &.is-open {
            border-color: var(--color-secondary-100);
        }

        &-option.is-selected, &-option.is-selected.is-pointed {
            background: var(--color-secondary-100);
        }

    }
}

body .multiselect {
    font-size: 14px;
    height: 46px;

    &, &.is-open {
        border-radius: 8px;
    }

    &.is-open {
        border-color: var(--color-tertiary-100);
        box-shadow: none;
    }

    &-option.is-pointed {
        background: var(--color-grey-100);
    }

    &-option.is-selected, &-option.is-selected.is-pointed {
        background: var(--color-tertiary-100);
    }

    &-dropdown {
        box-shadow: rgb(0 0 0 / 12%) 0 3px 6px -4px, rgb(0 0 0 / 8%) 0 6px 16px, rgb(0 0 0 / 5%) 0 9px 28px 8px;
        border: 0;
        bottom: -1px;
        border-top: 8px solid white;
    }

    &-clear {
        display: none;
    }

    &-caret {
        position: absolute;
        top: calc(50% + 2px);
        right: 0;
        width: 0;
        height: 0;
        display: inline-block;
        border: 5px solid transparent;
        border-top-color: var(--color-grey-500);
        opacity: 1;
        transform: translateY(-50%);
    }

    &-caret.is-open {
        transform: translateY(-100%) rotate(180deg);
    }

    &-single-label {
        color: var(--color-grey-900);
    }

    &-placeholder {
        color: var(--color-grey-400);
    }

    &__select {
        position: absolute;
        top: 4px;
        right: 4px;

        &:before {
            border: 5px solid var(--color-grey-900);
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom: 0;
            transform: none;
        }
    }

    &-search {
        width: calc(100% - 8px);
        top: 2px;
        bottom: 2px;
        left: 2px;
    }

    &-option {
        white-space: unset;
        word-break: break-all;
        font-size: 14px;
    }
}
