:root {
    --color-secondary-100: #5E4AE3;
    --color-secondary-200: #4B3BB6;

    --color-blue-100: #F7F6FE;
    --color-blue-200: #DFDBF9;
    --color-blue-300: #BFB7F4;
    --color-blue-400: #9E92EE;
    --color-blue-500: #7E6EE9;
    --color-blue-600: #5E4AE3;
    --color-blue-700: #4B3BB6;
    --color-blue-800: #382C88;
    --color-blue-900: #261E5B;
    --color-blue-1000: #130F2D;
    --color-blue-1100: #090717;

    --color-tertiary-100: #FF59AE;
    --color-tertiary-200: #CC478B;

    --color-pink-100: #F8F6F7;
    --color-pink-200: #FFDEEF;
    --color-pink-300: #FFBDDF;
    --color-pink-400: #FF9BCE;
    --color-pink-500: #FF7ABE;
    --color-pink-600: #FF59AE;
    --color-pink-700: #CC478B;
    --color-pink-800: #993568;
    --color-pink-900: #662446;
    --color-pink-1000: #331223;
    --color-pink-1100: #190911;


    //--color-base-100: #D3E2EE;
    --color-base-200: #BFCEE4;
    --color-base-300: #F4FAFF;

    --color-border: var(--color-base-200);

    --color-grey-100: #FAFAFA;
    --color-grey-200: #EEEEEE;
    --color-grey-300: #CCCCCC;
    --color-grey-400: #BBBBBB;
    --color-grey-500: #AAAAAA;
    --color-grey-600: #888888;
    --color-grey-700: #777777;
    --color-grey-800: #666666;
    --color-grey-900: #555555;
    --color-grey-1000: #333333;
    --color-grey-1100: #111111;

    --color-red-100: #FEF7F8;
    --color-red-200: #FDDEE1;
    --color-red-300: #FBBDC3;
    --color-red-400: #F89DA5;
    --color-red-500: #F67C87;
    --color-red-600: #F45B69;
    --color-red-700: #C34954;
    --color-red-800: #92373F;
    --color-red-900: #62242A;
    --color-red-1000: #311215;
    --color-red-1100: #18090A;

    --color-green-100: #F7FEFA;
    --color-green-200: #DFF9EA;
    --color-green-300: #C0F3D4;
    --color-green-400: #A0EEBF;
    --color-green-500: #81E8A9;
    --color-green-600: #61E294;
    --color-green-700: #4EB576;
    --color-green-800: #3A8859;
    --color-green-900: #275A3B;
    --color-green-1000: #132D1E;
    --color-green-1100: #0A170F;

    --color-yellow-100: #FEFDF7;
    --color-yellow-200: #FDF6DF;
    --color-yellow-300: #FBEDBF;
    --color-yellow-400: #F8E59E;
    --color-yellow-500: #F6DC7E;
    --color-yellow-600: #F4D35E;
    --color-yellow-700: #C3A94B;
    --color-yellow-800: #927F38;
    --color-yellow-900: #625426;
    --color-yellow-1000: #312A13;
    --color-yellow-1100: #181509;

    --sidebar-width: 224px;
    --header-height: 72px;
}
