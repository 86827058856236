body {
    font: 400 16px/1.3 'Roboto', sans-serif;
    color: var(--color-grey-700);
    background: var(--color-pink-100);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header-height-padding {
    padding-top: var(--header-height);
}

.container {
    max-width: 1012px;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
}

.pos-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.m-h-screen{
    min-height: 100vh;
}

.transition-3s{
    transition: .3s all;
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
