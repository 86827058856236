:root {
  --color-secondary-100: #5E4AE3;
  --color-secondary-200: #4B3BB6;
  --color-blue-100: #F7F6FE;
  --color-blue-200: #DFDBF9;
  --color-blue-300: #BFB7F4;
  --color-blue-400: #9E92EE;
  --color-blue-500: #7E6EE9;
  --color-blue-600: #5E4AE3;
  --color-blue-700: #4B3BB6;
  --color-blue-800: #382C88;
  --color-blue-900: #261E5B;
  --color-blue-1000: #130F2D;
  --color-blue-1100: #090717;
  --color-tertiary-100: #FF59AE;
  --color-tertiary-200: #CC478B;
  --color-pink-100: #F8F6F7;
  --color-pink-200: #FFDEEF;
  --color-pink-300: #FFBDDF;
  --color-pink-400: #FF9BCE;
  --color-pink-500: #FF7ABE;
  --color-pink-600: #FF59AE;
  --color-pink-700: #CC478B;
  --color-pink-800: #993568;
  --color-pink-900: #662446;
  --color-pink-1000: #331223;
  --color-pink-1100: #190911;
  --color-base-200: #BFCEE4;
  --color-base-300: #F4FAFF;
  --color-border: var(--color-base-200);
  --color-grey-100: #FAFAFA;
  --color-grey-200: #EEEEEE;
  --color-grey-300: #CCCCCC;
  --color-grey-400: #BBBBBB;
  --color-grey-500: #AAAAAA;
  --color-grey-600: #888888;
  --color-grey-700: #777777;
  --color-grey-800: #666666;
  --color-grey-900: #555555;
  --color-grey-1000: #333333;
  --color-grey-1100: #111111;
  --color-red-100: #FEF7F8;
  --color-red-200: #FDDEE1;
  --color-red-300: #FBBDC3;
  --color-red-400: #F89DA5;
  --color-red-500: #F67C87;
  --color-red-600: #F45B69;
  --color-red-700: #C34954;
  --color-red-800: #92373F;
  --color-red-900: #62242A;
  --color-red-1000: #311215;
  --color-red-1100: #18090A;
  --color-green-100: #F7FEFA;
  --color-green-200: #DFF9EA;
  --color-green-300: #C0F3D4;
  --color-green-400: #A0EEBF;
  --color-green-500: #81E8A9;
  --color-green-600: #61E294;
  --color-green-700: #4EB576;
  --color-green-800: #3A8859;
  --color-green-900: #275A3B;
  --color-green-1000: #132D1E;
  --color-green-1100: #0A170F;
  --color-yellow-100: #FEFDF7;
  --color-yellow-200: #FDF6DF;
  --color-yellow-300: #FBEDBF;
  --color-yellow-400: #F8E59E;
  --color-yellow-500: #F6DC7E;
  --color-yellow-600: #F4D35E;
  --color-yellow-700: #C3A94B;
  --color-yellow-800: #927F38;
  --color-yellow-900: #625426;
  --color-yellow-1000: #312A13;
  --color-yellow-1100: #181509;
  --sidebar-width: 224px;
  --header-height: 72px;
}

svg {
  min-width: 16px;
  width: 16px;
  height: 16px;
  fill: currentColor;
}

input {
  border-color: var(--color-border);
}

hr {
  margin: 4px 0 0;
  border-color: var(--color-border);
}

li, ul {
  list-style: none;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}
button:focus, button:active {
  outline: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration, select {
  -webkit-appearance: none;
}

body {
  font: 400 16px/1.3 "Roboto", sans-serif;
  color: var(--color-grey-700);
  background: var(--color-pink-100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-height-padding {
  padding-top: var(--header-height);
}

.container {
  max-width: 1012px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}

.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.m-h-screen {
  min-height: 100vh;
}

.transition-3s, .btn-action {
  transition: 0.3s all;
}

.flex-center, body .ant-table-row-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.btn--outline {
  border: 1px solid var(--color-blue-400);
  color: var(--color-blue-400);
}
.btn--outline:focus {
  border: 1px solid var(--color-blue-400);
}

.btn-link {
  font-weight: normal;
  color: var(--color-tertiary-100);
}
.btn-link:hover {
  color: var(--color-tertiary-100);
}
.btn-link--hover {
  color: inherit;
}

.pattern .btn-link {
  color: var(--color-secondary-100);
}
.pattern .btn-link:hover {
  color: var(--color-secondary-100);
}

.btn-action {
  display: inline-flex;
  width: 28px;
  height: 28px;
  border: 1px solid var(--color-grey-500);
  border-radius: 4px;
}
.btn-action svg {
  min-width: auto;
  width: 14px;
  height: 14px;
  margin: auto;
}
.btn-action--pdf svg {
  height: 14px;
}
.btn-action--success {
  border: 1px solid var(--color-green-700);
  color: var(--color-green-700);
}
.btn-action--success:hover {
  border: 1px solid var(--color-green-800);
  color: var(--color-green-800);
}
.btn-action--fail {
  border: 1px solid var(--color-red-500);
  color: var(--color-red-500);
}
.btn-action--fail:hover {
  border: 1px solid var(--color-red-600);
  color: var(--color-red-600);
}
.btn-action--primary {
  color: var(--color-tertiary-100);
  border-color: var(--color-tertiary-100);
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ic-close {
  width: 32px;
  height: 32px;
  opacity: 0.8;
  cursor: pointer;
  position: relative;
}
.ic-close:hover {
  opacity: 1;
}
.ic-close:before, .ic-close:after {
  position: absolute;
  left: 15px;
  top: 6px;
  content: " ";
  height: 22px;
  width: 2px;
  background-color: var(--color-grey-500);
}
.ic-close:before {
  transform: rotate(45deg);
}
.ic-close:after {
  transform: rotate(-45deg);
}

.ic-close--modal {
  position: absolute;
  top: 12px;
  right: 12px;
}
@media (max-width: 576px) {
  .ic-close--modal {
    top: 4px;
    right: 8px;
  }
}

.btn-wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 576px) {
  .btn-wrap {
    display: grid;
    gap: 24px;
  }
}

.form-btn-wrap a, .form-btn-wrap button {
  padding: 10px 40px;
}
@media (max-width: 576px) {
  .form-btn-wrap a, .form-btn-wrap button {
    padding: 8px 32px;
  }
}

.tooltip {
  min-width: 56px;
  position: absolute;
  left: -32px;
  bottom: 160%;
  padding: 4px 8px;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(46, 49, 51, 0.16);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  border-radius: 6px;
  pointer-events: none;
}
.tooltip--right {
  left: auto;
  right: -16px;
}
.tooltip:before {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  left: 40px;
  bottom: -4px;
  transform: rotate(45deg);
  background: #ffff;
}
.tooltip--right:before {
  left: auto;
  right: 26px;
}
.tooltip__wrapper:hover .tooltip {
  bottom: 140%;
  visibility: visible;
  opacity: 1;
}
.tooltip__wrapper--info .tooltip {
  bottom: 125%;
}
.tooltip__wrapper--info:hover .tooltip {
  bottom: 100%;
}

table tr:first-child td:first-child .tooltip__wrapper .tooltip, table tr:first-child td:nth-child(2) .tooltip__wrapper .tooltip {
  right: 140%;
  bottom: 50%;
  left: auto;
  transform: translateY(50%);
}
table tr:first-child td:first-child .tooltip__wrapper .tooltip:before, table tr:first-child td:nth-child(2) .tooltip__wrapper .tooltip:before {
  bottom: 50%;
  right: -4px;
  left: auto;
  transform: translateY(50%) rotate(-45deg);
}
table tr:first-child td:first-child .tooltip__wrapper:hover .tooltip, table tr:first-child td:nth-child(2) .tooltip__wrapper:hover .tooltip {
  right: 130%;
}

.table-tooltip-break tr:first-child .tooltip {
  min-width: 5em;
  white-space: pre-wrap;
  text-align: right;
}

.tooltip__wrapper--info .tooltip {
  pointer-events: auto;
  min-width: 245px;
}
@media (max-width: 576px) {
  .tooltip__wrapper--info .tooltip {
    right: -40px;
    left: auto;
  }
}
@media (max-width: 576px) {
  .tooltip__wrapper--info .tooltip:before {
    left: auto;
    right: 51px;
  }
}

.table-head-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 32px;
}
@media (max-width: 576px) {
  .table-head-container {
    padding: 24px;
  }
}

.table-sort-buttons {
  display: flex;
}
.table-sort-buttons button {
  margin-left: 8px;
}

table, .table {
  width: 100%;
  text-align: left;
  font-size: 12px;
  white-space: nowrap;
  text-transform: capitalize;
}
table thead th:first-child, table .thead > div:first-child, table .accordion__item > div:first-child, .table thead th:first-child, .table .thead > div:first-child, .table .accordion__item > div:first-child {
  padding-left: 40px;
  width: 74px;
}
table tbody th:first-child, table tr td:first-child, .table tbody th:first-child, .table tr td:first-child {
  padding-left: 48px;
}
table td, table th, table .thead > *, .table td, .table th, .table .thead > * {
  padding: 16px 12px;
  border: 0;
}
table body tr, .table body tr {
  border-bottom: 1px solid var(--color-grey-200);
}
table body tr:hover td, .table body tr:hover td {
  background: transparent;
}
table thead, .table thead {
  background: var(--color-pink-100);
  color: var(--color-grey-900);
  text-transform: capitalize;
}
table th, table .thead > *, .table th, .table .thead > * {
  font-size: 12px;
  font-weight: 700;
}
table .table__head, .table .table__head {
  display: flex;
}
table .tr:nth-child(even), .table .tr:nth-child(even) {
  background: #F8F6F7;
}
table table, .table table {
  background: #F8F6F7;
}
table table thead, .table table thead {
  background: #e5e5e5;
  border-bottom: 2px solid #fff;
}
table table tr, .table table tr {
  background: var(--color-grey-100) !important;
}

.test-work-order table thead th:last-child {
  display: flex;
}
.test-work-order table thead th:last-child .ant-table-header-column {
  margin: auto;
}

.pattern .ant-table {
  color: inherit;
}
.pattern .ant-table .ant-table-thead > tr > th {
  background-color: var(--color-blue-100);
  border: 0;
}
.pattern .ant-table .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: flex;
}
@media (max-width: 576px) {
  .pattern .ant-table-layout-fixed table {
    table-layout: auto;
  }
}

.caret {
  transition: 0.3s all;
}

.sort-icons svg {
  min-width: auto;
  width: 10px;
  height: 6px;
}

.pattern .ant-table .ant-table-thead > tr > th {
  background-color: var(--color-blue-100);
}

body .ant-checkbox-wrapper {
  position: relative;
  cursor: pointer;
}
body .ant-checkbox-wrapper input[type=checkbox] {
  display: none;
}
body .ant-checkbox-wrapper:before, body .ant-checkbox-wrapper:after {
  content: "";
}
body .ant-checkbox-wrapper:hover:before {
  border-color: var(--color-tertiary-100);
}
body .ant-checkbox-wrapper:before {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ccc;
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
body .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:before {
  border-color: var(--color-tertiary-100);
  background: var(--color-tertiary-100);
}
body .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:after {
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(43deg);
}
body .ant-checkbox-wrapper + span {
  font-size: 12px;
}
body .ant-checkbox-wrapper.ant-checkbox-wrapper-checked + span {
  color: var(--color-grey-900);
}
body .ant-table {
  color: inherit;
}
body .ant-table .ant-table-thead > tr > th {
  color: var(--color-grey-900);
}
body .ant-table .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  margin-left: 2px;
}
body .ant-table .anticon-caret-up {
  margin-bottom: -1px !important;
}
body .ant-table .anticon-caret-up svg, body .ant-table .anticon-caret-down svg {
  width: 14px;
  height: 14px;
}
body .ant-table thead th {
  background-color: var(--color-pink-100);
}
body .ant-table-thead > tr > th {
  border: transparent;
}
body .ant-table tbody tr td {
  border-bottom: 1px solid var(--color-grey-200);
}
body .ant-table tr:hover td {
  background: transparent !important;
}
body .ant-table .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: flex;
}
body .ant-table .ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort, body .ant-table .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background-color: var(--color-grey-200);
}
body .ant-table .anticon.on, body .ant-table .ant-table-filter-selected {
  color: var(--color-grey-900) !important;
}
body .ant-table-row-hover td, body .ant-table-row:hover, body .ant-table tr.ant-table-row-selected td {
  background-color: var(--color-grey-100) !important;
}
body .ant-table-filter-dropdown {
  min-width: 156px !important;
  border-radius: 8px;
  box-shadow: 8px 8px 8px 0 #6624461A;
  z-index: 1000;
}
body .ant-table-filter-dropdown-link {
  cursor: pointer;
  transition: 0.3s all;
  font-size: 12px;
}
body .ant-table-filter-dropdown-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .ant-table-filter-dropdown-link.confirm {
  background: var(--color-tertiary-100);
  color: #fff;
  padding: 1px 12px;
  font-size: 12px;
  border-radius: 4px;
}
body .ant-table-filter-dropdown-link.confirm:hover {
  background: var(--color-tertiary-200);
}
body .ant-table-row-expand-icon {
  width: 28px;
  height: 28px;
  color: var(--color-tertiary-100) !important;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid var(--color-tertiary-100);
}
body .ant-table-filter-dropdown-link.clear {
  color: var(--color-grey-400);
}
body .ant-table-filter-dropdown-link.clear:hover {
  color: var(--color-grey-300);
}
body .ant-empty-image {
  display: none;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
  background-color: transparent !important;
}

.anticon-search {
  color: var(--color-grey-400) !important;
}
.anticon-search.ant-table-filter-open {
  color: var(--color-grey-900) !important;
}

.ant-dropdown .ant-input, .ant-dropdown .ant-btn, .ant-dropdown .ant-reset {
  font-size: 12px;
}
.ant-dropdown .ant-input {
  padding: 4px 8px !important;
  border-radius: 4px !important;
}
.ant-dropdown .ant-input:focus {
  border-color: var(--color-tertiary-100);
  box-shadow: none;
}
.ant-dropdown .ant-reset {
  color: var(--color-grey-400);
  border: 0;
}
.ant-dropdown .ant-reset:hover {
  color: var(--color-grey-300);
}
.ant-dropdown .ant-btn-primary {
  display: flex;
  background-color: var(--color-tertiary-100);
  border-radius: 4px;
  border: 0;
}
.ant-dropdown .ant-btn-primary:hover {
  background-color: var(--color-tertiary-200);
}
.ant-dropdown .ant-dropdown-menu-item:hover {
  background: transparent;
}
.ant-dropdown .ant-dropdown-menu-item:hover label:before {
  border-color: var(--color-tertiary-100);
}
.ant-dropdown .ant-dropdown-menu-item:hover span {
  color: var(--color-grey-900);
}
.ant-dropdown .ant-dropdown-menu-title-content {
  color: var(--color-grey-400);
}

.patient-dashboard .ant-table-filter-dropdown-link.confirm {
  background: var(--color-secondary-100) !important;
}
.patient-dashboard .ant-table-filter-dropdown-link.confirm:hover {
  background: var(--color-secondary-200) !important;
}
.patient-dashboard .ant-dropdown .ant-dropdown-menu-item:hover {
  background: transparent;
}
.patient-dashboard .ant-dropdown .ant-dropdown-menu-item:hover label:before {
  border-color: var(--color-secondary-100);
}
.patient-dashboard .ant-dropdown .ant-dropdown-menu-title-content label.ant-checkbox-wrapper-checked:before {
  border-color: var(--color-secondary-100) !important;
  background: var(--color-secondary-100) !important;
}
.patient-dashboard .ant-dropdown .ant-dropdown-menu-title-content label.ant-checkbox-wrapper-checked:after {
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(43deg);
}

.pending-test-table table tbody tr td:first-child {
  padding-left: 40px;
}

.modal-content td, .modal-content th {
  padding: 16px !important;
}

body .ant-table-pagination.ant-pagination {
  margin: 24px 16px 0;
}

.anticon.anticon-delete {
  color: red;
}

.card {
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0 8px 80px -40px #261E5B80;
  border-radius: 8px;
}
@media (max-width: 576px) {
  .card {
    padding: 24px;
  }
}

.card--large {
  box-shadow: 0 8px 80px -40px #261E5B80;
  background: #FFFFFF;
  padding: 64px;
  border-radius: 16px;
  overflow: hidden;
}
@media (max-width: 1400px) {
  .card--large {
    padding: 40px;
  }
}
@media (max-width: 576px) {
  .card--large {
    padding: 24px;
  }
}

.heading-primary {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.375;
  color: var(--color-grey-1000);
}
@media (max-width: 576px) {
  .heading-primary {
    font-size: 24px;
  }
}

.brand-title {
  color: #fff;
}
.brand-title span {
  color: var(--color-tertiary-100);
}

.heading-primary-md {
  font-size: 24px;
}

.heading-primary-sm {
  font-size: 20px;
  line-height: 1.15;
}

.heading-secondary-sm {
  font-weight: 700;
  color: var(--color-grey-800);
  font-size: 18px;
}

.form-header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--color-pink-200);
}
.form-header svg {
  margin-right: 12px;
  width: 30px;
  height: 30px;
}

.pattern .form-header {
  border-bottom: 1px solid var(--color-blue-600);
}

.pattern .form-header {
  border-color: var(--color-blue-200);
}

.radio-btn-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.radio-btn-group label {
  cursor: pointer;
  position: relative;
  display: flex;
  color: #bbb;
  transition: all 0.1s;
}
.radio-btn-group label:before, .radio-btn-group label:after {
  border-radius: 50%;
  content: " ";
  transition: all 0.1s;
}
.radio-btn-group label:before {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 8px;
  min-width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  background-color: transparent;
}
.radio-btn-group input[type=radio] {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}
.radio-btn-group input[type=radio]:checked + label, .radio-btn-group input[type=radio]:hover + label {
  color: var(--color-grey-900);
}
.radio-btn-group input[type=radio]:checked + label:before, .radio-btn-group input[type=radio]:hover + label:before {
  border-color: var(--color-secondary-100);
}
.radio-btn-group input[type=radio]:checked + label:after {
  width: 8px;
  height: 8px;
  position: absolute;
  top: 6px;
  left: 4px;
  background: var(--color-secondary-100);
}
.radio-btn-group--secondary input[type=radio]:checked + label:before, .radio-btn-group--secondary input[type=radio]:hover + label:before {
  border-color: #FF59AE;
}
.radio-btn-group--secondary input[type=radio]:checked + label:after {
  background: #FF59AE;
}

.custom-checkbox {
  display: flex;
  align-items: center;
}
.custom-checkbox input {
  opacity: 0;
  height: 0;
  width: 0;
}
.custom-checkbox label {
  position: relative;
  cursor: pointer;
  color: var(--color-grey-400);
  line-height: 0;
}
.custom-checkbox input:checked + label:before {
  border-color: var(--color-tertiary-100);
  background: var(--color-tertiary-100);
}
.custom-checkbox label:hover:before {
  border-color: var(--color-tertiary-100);
}
.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #ccc;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 4px;
}
@media (max-width: 576px) {
  .custom-checkbox label:before {
    width: 18px;
    height: 18px;
  }
}
.custom-checkbox--single label:before {
  border-color: var(--color-tertiary-100);
}
.custom-checkbox input:checked + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(43deg);
}
@media (max-width: 576px) {
  .custom-checkbox input:checked + label:after {
    top: 2px;
    left: 7px;
  }
}
.custom-checkbox input:indeterminate + label:before {
  border-color: var(--color-tertiary-100);
  background: var(--color-tertiary-100);
}
.custom-checkbox input:indeterminate + label:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  width: 8px;
  height: 1px;
  background: #fff;
}
.custom-checkbox:not(.custom-checkbox--single, .custom-checkbox--secondary) input:checked + label, .custom-checkbox:not(.custom-checkbox--single, .custom-checkbox--secondary) label:hover {
  color: var(--color-grey-900);
}
.custom-checkbox--secondary input:checked + label, .custom-checkbox--secondary label:hover {
  color: var(--color-grey-900);
}
.custom-checkbox--secondary input:checked + label:before {
  border-color: var(--color-secondary-100);
  background: var(--color-secondary-100);
}
.custom-checkbox--secondary label:hover:before {
  border-color: var(--color-secondary-100);
}
.custom-checkbox--secondary input:indeterminate + label:before {
  border-color: var(--color-secondary-100);
  background: var(--color-secondary-100);
}

.file__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.file__input-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 6px;
  border: 1px solid var(--color-border);
}
.file__input-wrap:hover .file__input-trigger {
  opacity: 0.8;
}
.file__input-trigger {
  background: var(--color-secondary-200);
  color: #fff;
  padding: 10px 8px;
  border-radius: 8px;
  font-size: 12px;
  pointer-events: none;
}
.file__input--secondary .file__input-trigger {
  background: #FF59AE;
}

.file__list {
  display: flex;
  gap: 12px;
  background: var(--color-grey-200);
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
}
.file__item {
  border-radius: 4px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  position: relative;
}
.file__item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: 0.3s all;
}
.file__item:hover:before {
  background: rgba(0, 0, 0, 0.2);
}
.file__item img {
  width: 100%;
  height: 100%;
}
.file__delete {
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 50%;
  background: #f53849;
  color: #fff;
  padding: 2px;
  cursor: pointer;
}
.file__name {
  font-size: 10px;
}

.search-req {
  overflow: visible;
  max-width: 540px;
  box-shadow: 2px 2px 4px rgba(102, 36, 70, 0.1);
}
.search-req .form-control, .search-req .form-control--suggestion .ant-input, .form-control--suggestion .search-req .ant-input, .search-req .ant-dropdown .ant-input, .ant-dropdown .search-req .ant-input {
  border: 0;
}
.search-req .ant-select-selection-placeholder {
  padding: 8px 16px !important;
}

.search-form input {
  padding-top: 9px;
  padding-bottom: 9px;
}

.form-icon {
  position: relative;
  color: #aaa;
}
.form-icon svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 4;
  pointer-events: none;
}
.form-icon-no-border {
  color: var(--color-grey-300);
}
.form-icon-no-border svg {
  left: 2px;
  right: auto;
  fill: var(--color-grey-400);
}
.form-icon-no-border .form-control, .form-icon-no-border .form-control--suggestion .ant-input, .form-control--suggestion .form-icon-no-border .ant-input, .form-icon-no-border .ant-dropdown .ant-input, .ant-dropdown .form-icon-no-border .ant-input {
  padding-left: 30px;
  font-size: 16px;
}
.form-icon .form-control, .form-icon .form-control--suggestion .ant-input, .form-control--suggestion .form-icon .ant-input, .form-icon .ant-dropdown .ant-input, .ant-dropdown .form-icon .ant-input {
  cursor: pointer;
  caret-color: transparent;
}

.search-result {
  z-index: 1028;
}
.search-result-list {
  max-height: 300px;
  overflow-y: auto;
}
.search-result li {
  border-top: 1px solid var(--color-grey-200);
}
.search-result .autocomplete-result.is-active {
  background-color: #f2f2f2;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #eee #ccc;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 50px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 50px;
}

.custom-scrollbar--secondary {
  scrollbar-width: thin;
  scrollbar-color: #eee var(--color-secondary-100);
}
.custom-scrollbar--secondary::-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar--secondary::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 50px;
}
.custom-scrollbar--secondary::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary-100);
  border-radius: 50px;
}

.multiselect-dropdown {
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: var(--color-grey-100) var(--color-grey-300);
  box-shadow: 8px 8px 18px 0 #6624461A;
}
.multiselect-dropdown::-webkit-scrollbar {
  width: 8px;
}
.multiselect-dropdown::-webkit-scrollbar-track {
  background: var(--color-grey-100);
  border-radius: 5px;
}
.multiselect-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--color-grey-300);
  border-radius: 5px;
}

.test-work-order .multiselect-dropdown {
  min-width: 140px;
  right: 0;
  left: auto;
}

button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.form-control, .form-control--suggestion .ant-input, .ant-dropdown .ant-input {
  border: 1px solid var(--color-grey-300);
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
}
.form-control:focus, .form-control--suggestion .ant-input:focus, .ant-dropdown .ant-input:focus, .form-control:focus-visible, .form-control--suggestion .ant-input:focus-visible, .ant-dropdown .ant-input:focus-visible {
  outline: none;
}

.pattern .form-control:focus, .pattern .form-control--suggestion .ant-input:focus, .form-control--suggestion .pattern .ant-input:focus, .pattern .ant-dropdown .ant-input:focus, .ant-dropdown .pattern .ant-input:focus {
  border-color: var(--color-secondary-100);
}

.label {
  display: block;
  color: var(--color-grey-500);
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 700;
  text-transform: capitalize;
}

.form-control-password {
  height: 46px;
  font-size: 24px;
  font-family: caption, sans-serif;
}

.card--empty {
  min-height: 348px;
  height: calc(100vh - 332px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-error input[type=text], .input-error input[type=email], .input-error .file__input-wrap, .input-error .form-control, .input-error .form-control--suggestion .ant-input, .form-control--suggestion .input-error .ant-input, .input-error .ant-dropdown .ant-input, .ant-dropdown .input-error .ant-input {
  border-color: var(--color-red-500);
}
.input-error select, .input-error .multiselect {
  border-color: var(--color-red-500);
}

.label-error {
  display: block;
  color: var(--color-red-500);
  font-weight: 500;
  font-size: 14px;
  margin-top: 6px;
}

.input-error .no-error .multiselect {
  border: 1px solid var(--color-grey-300) !important;
}

.form-control:disabled, .ant-dropdown .ant-input:disabled, .form-control--suggestion .ant-input:disabled, .multiselect.is-disabled {
  background: var(--color-grey-100) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 0 !important;
}

.ant-select-dropdown {
  box-shadow: 8px 8px 18px 0 #6624461A !important;
  z-index: 1020 !important;
}
.ant-select-dropdown .ant-select-item-option-selected {
  background: var(--color-tertiary-100) !important;
  color: #fff !important;
  font-weight: normal !important;
}

.ant-select-item {
  color: var(--color-grey-900);
}

.ant-select-item-active:not(.ant-select-item-option-selected) {
  background: var(--color-grey-100) !important;
}

.dropdown {
  box-shadow: 8px 8px 30px 0 #6624461A;
  background-color: #fff;
  border-radius: 8px;
  min-width: 216px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 9;
  display: none;
}
.dropdown__trigger {
  cursor: pointer;
}
.dropdown li a {
  display: block;
  padding: 16px 24px;
}
.dropdown li:not(:first-child) {
  border-top: 1px solid #F8F6F7;
}
.dropdown__menu {
  position: relative;
}
.dropdown--show.dropdown {
  display: block;
}

.filter-dropdown .dropdown {
  width: 414px;
  font-size: 12px;
  box-shadow: 8px 8px 40px rgba(95, 127, 178, 0.2);
}

.pattern .multiselect.is-open {
  border-color: var(--color-secondary-100);
}
.pattern .multiselect-option.is-selected, .pattern .multiselect-option.is-selected.is-pointed {
  background: var(--color-secondary-100);
}

body .multiselect {
  font-size: 14px;
  height: 46px;
}
body .multiselect, body .multiselect.is-open {
  border-radius: 8px;
}
body .multiselect.is-open {
  border-color: var(--color-tertiary-100);
  box-shadow: none;
}
body .multiselect-option.is-pointed {
  background: var(--color-grey-100);
}
body .multiselect-option.is-selected, body .multiselect-option.is-selected.is-pointed {
  background: var(--color-tertiary-100);
}
body .multiselect-dropdown {
  box-shadow: rgba(0, 0, 0, 0.12) 0 3px 6px -4px, rgba(0, 0, 0, 0.08) 0 6px 16px, rgba(0, 0, 0, 0.05) 0 9px 28px 8px;
  border: 0;
  bottom: -1px;
  border-top: 8px solid white;
}
body .multiselect-clear {
  display: none;
}
body .multiselect-caret {
  position: absolute;
  top: calc(50% + 2px);
  right: 0;
  width: 0;
  height: 0;
  display: inline-block;
  border: 5px solid transparent;
  border-top-color: var(--color-grey-500);
  opacity: 1;
  transform: translateY(-50%);
}
body .multiselect-caret.is-open {
  transform: translateY(-100%) rotate(180deg);
}
body .multiselect-single-label {
  color: var(--color-grey-900);
}
body .multiselect-placeholder {
  color: var(--color-grey-400);
}
body .multiselect__select {
  position: absolute;
  top: 4px;
  right: 4px;
}
body .multiselect__select:before {
  border: 5px solid var(--color-grey-900);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom: 0;
  transform: none;
}
body .multiselect-search {
  width: calc(100% - 8px);
  top: 2px;
  bottom: 2px;
  left: 2px;
}
body .multiselect-option {
  white-space: unset;
  word-break: break-all;
  font-size: 14px;
}

.tab__list {
  display: flex;
  border-bottom: 1px solid var(--color-grey-200);
}
.tab__item {
  cursor: pointer;
  padding: 16px 24px;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
  color: var(--color-grey-500);
  transition: 0.3s all;
}
.tab__item:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 3px;
  width: 100%;
  background: var(--color-tertiary-100);
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s all;
}
.tab__item.is--active {
  color: var(--color-tertiary-100);
  position: relative;
  box-shadow: none;
}
.tab__item.is--active:before {
  visibility: visible;
  opacity: 1;
}
.tab__content {
  padding: 32px;
}
.tab__content-item {
  display: none;
}
.tab__content-item.is--active {
  display: block;
  opacity: 1;
}

.pattern .tab__item:before {
  background: var(--color-secondary-100);
}
.pattern .tab__item.is--active {
  color: var(--color-secondary-100);
}

.vc-highlight, .vc-am-pm .active {
  background: var(--color-tertiary-100) !important;
}

.vc-time-day, .vc-time-month {
  color: var(--color-tertiary-100) !important;
}

.vc-weekday {
  color: var(--color-grey-500);
  font-size: 14px;
}

.pattern .vc-highlight, .pattern .vc-am-pm .active {
  background: var(--color-secondary-100) !important;
}
.pattern .vc-time-day, .pattern .vc-time-month {
  color: var(--color-secondary-100) !important;
}

.separator-dashed {
  border: 1px dashed var(--color-grey-300);
}

.ant-calendar-panel .ant-calendar-today .ant-calendar-date {
  border-color: var(--color-tertiary-100);
  color: var(--color-tertiary-100);
}
.ant-calendar-panel .ant-calendar-selected-day .ant-calendar-date {
  background: var(--color-tertiary-100);
  border-color: var(--color-tertiary-100);
  color: #fff;
}
.ant-calendar-panel .ant-calendar-date:active {
  background: unset;
  color: unset;
}
.ant-calendar-panel .ant-calendar-disabled-cell .ant-calendar-date, .ant-calendar-panel .ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: var(--color-pink-200);
}
.ant-calendar-panel .ant-calendar-disabled-cell .ant-calendar-date:before {
  display: none;
}
.ant-calendar-panel .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  border-color: var(--color-pink-300);
  color: var(--color-pink-500);
}

.ant-calendar-picker-input, .ant-calendar-picker-input.ant-input {
  cursor: pointer;
  border: 0;
  padding: 3px 0;
}
.ant-calendar-picker-input:focus, .ant-calendar-picker-input.ant-input:focus {
  outline: none;
}

.ant-calendar-picker:hover .anticon-close-circle {
  display: none !important;
}

.alert-success {
  background: var(--color-green-100);
}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  bottom: 16px;
  right: 32px;
  z-index: 1010;
  pointer-events: none;
}
.ant-message > span {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.ant-message-notice {
  display: inline-flex;
  animation: fadeIn 0.3s forwards;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  animation: fadeOut 0.3s forwards;
}

.ant-message-notice-content {
  border-radius: 8px;
  padding: 0;
  display: flex;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
  overflow: hidden;
}

.ant-message-success {
  color: var(--color-green-700);
  background: var(--color-green-100);
}

.ant-message-error {
  color: var(--color-red-600);
  background: var(--color-red-100);
}

.ant-message-warning {
  color: #faad14;
  background: var(--color-yellow-100);
}

.ant-message-info {
  color: #1890ff;
  background: #dbedff;
}

.ant-message .anticon {
  margin-right: 8px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
}

.ant-message-content {
  box-shadow: 4px 4px 40px rgba(95, 127, 178, 0.2);
}

.ant-message-custom-content {
  padding: 12px 16px;
  display: inline-flex;
  align-items: center;
}

.pattern {
  background: url("/images/pattern.png");
}

@media (max-width: 1024px) {
  .sidebar-reg__list {
    display: flex;
    overflow-x: auto;
  }
}

.login-registration .sidebar-reg__list > div:first-child, .login-registration .sidebar-reg__title {
  display: none;
}

.header-height-deduct {
  min-height: calc(100vh - var(--header-height));
}

.header-footer-height-deduct {
  min-height: calc(100vh - 140px);
}

.registration-welcome {
  height: calc(100vh - 140px);
}
@media (max-width: 576px) {
  .registration-welcome {
    min-height: calc(100vh - 140px);
    height: auto;
  }
}

.form-header svg {
  width: 26px;
  height: 26px;
}

.sidebar {
  width: var(--sidebar-width);
  position: fixed;
  top: var(--header-height);
  left: 0;
  height: 100vh;
  font-size: 14px;
}
.sidebar__link {
  border-bottom: 1px solid var(--color-grey-200);
}

.content {
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  padding: 24px;
}

.user-dropdown .dropdown {
  right: 16px;
  margin-top: -12px;
}

.user-dropdown__trigger {
  border-left: 1px solid #F8F6F705;
}

.accordion {
  font-size: 16px;
}
.accordion:not(:first-child) {
  border-top: 1px solid var(--color-border-2);
}
.accordion__title {
  padding: 16px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s all;
}
.accordion--active {
  background: var(--color-grey-100);
}
.accordion__content-wrap {
  background: #f9f9f9;
  overflow: hidden;
  transition: 0.3s all linear;
  max-height: 0;
}
.accordion__content {
  padding: 24px;
}
.accordion__arrow {
  transition: 0.4s;
  transform: rotate(90deg);
  width: 14px;
}
.accordion--active .accordion__arrow {
  transform: rotate(-90deg);
}

.content-area {
  min-height: calc(100vh - (72px + var(--header-height)));
}

.login-appointment-form .lg\:grid-cols-2 {
  grid-template-columns: repeat(3, minmax(0, 3fr));
}

.test-booking .hr-line .heading-secondary-sm {
  padding-top: 24px;
  border-top: 1px solid var(--color-grey-200);
}

.login {
  margin: auto 0;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 576px) {
  .login {
    width: 100%;
    height: inherit;
    margin: 0;
  }
}
.login__wrap {
  max-width: 100%;
  width: 1048px;
  margin: auto;
  padding: 64px 0;
}
@media (max-width: 576px) {
  .login__wrap {
    padding: 24px;
  }
}
.login__left {
  background: url("/images/img-log-page.jpg") no-repeat;
  background-size: cover;
}
@media (max-width: 576px) {
  .login__left {
    display: none;
  }
}
.login__left-content {
  height: 100%;
  margin: auto;
  background-color: rgba(17, 17, 17, 0.8);
  color: #fff;
  display: flex;
  text-align: center;
}
.login__left p {
  margin-top: 16px;
}
.login__form {
  padding: 64px 64px 24px;
}
@media (max-width: 576px) {
  .login__form {
    padding: 24px;
    border-radius: 8px;
  }
}
.login__form-title {
  padding-bottom: 24px;
}

.container--login {
  max-width: 1048px;
}

.modal--logout .modal-content {
  padding: 80px 0;
}

.pattern .payment-field {
  display: none;
}