.tooltip {
    min-width: 56px;
    position: absolute;
    left: -32px;
    bottom: 160%;
    padding: 4px 8px;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(46, 49, 51, 0.16);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    border-radius: 6px;
    pointer-events: none;

    &--right {
        left: auto;
        right: -16px;
    }

    &:before {
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        left: 40px;
        bottom: -4px;
        transform: rotate(45deg);
        background: #ffff;
    }

    &--right:before {
        left: auto;
        right: 26px;
    }

    &__wrapper:hover .tooltip {
        bottom: 140%;
        visibility: visible;
        opacity: 1;
    }

    &__wrapper--info {
        .tooltip {
            bottom: 125%;
        }

        &:hover .tooltip {
            bottom: 100%;
        }
    }

}


table {
    tr:first-child {
        td:first-child, td:nth-child(2) {
            .tooltip__wrapper .tooltip {
                right: 140%;
                bottom: 50%;
                left: auto;
                transform: translateY(50%);

                &:before {
                    bottom: 50%;
                    right: -4px;
                    left: auto;
                    transform: translateY(50%) rotate(-45deg);
                }
            }

            .tooltip__wrapper:hover .tooltip {
                right: 130%;
            }
        }
    }
}

.table-tooltip-break {
    tr:first-child {
        .tooltip {
            min-width: 5em;
            white-space: pre-wrap;
            text-align: right;
        }
    }
}

.tooltip__wrapper--info {
    .tooltip {
        pointer-events: auto;
        min-width: 245px;
        @include respond(sm) {
            right: -40px;
            left: auto;
        }

        &:before {
            @include respond(sm) {
                left: auto;
                right: 51px;
            }
        }
    }
}
