.table-head-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px 32px;

    @include respond(sm) {
        padding: 24px;
    }
}

.table-sort-buttons {
    display: flex;

    button {
        margin-left: 8px;
    }
}

table, .table {
    width: 100%;
    text-align: left;
    font-size: 12px;
    white-space: nowrap;
    text-transform: capitalize;

    thead th, .thead > div, .accordion__item > div {
        &:first-child {
            padding-left: 40px;
            width: 74px;
        }
    }

    tbody th, tr td {
        &:first-child {
            padding-left: 48px;
        }
    }

    td, th, .thead > * {
        padding: 16px 12px;
        border: 0;
    }

    body tr {
        border-bottom: 1px solid var(--color-grey-200);

        &:hover td {
            background: transparent;
        }
    }

    thead {
        background: var(--color-pink-100);
        color: var(--color-grey-900);
        text-transform: capitalize;
    }

    th, .thead > * {

        font-size: 12px;
        font-weight: 700;
    }

    .table__head {
        display: flex;
    }

    .tr:nth-child(even) {
        background: #F8F6F7;
    }

    table {
        background: #F8F6F7;
    }

    table {
        thead {
            background: #e5e5e5;
            border-bottom: 2px solid #fff;
        }

        tr {
            background: var(--color-grey-100) !important;
        }
    }
}

.test-work-order table thead th:last-child {
    display: flex;

    .ant-table-header-column {
        margin: auto;
    }
}

.pattern {
    .ant-table {
        color: inherit;
    }

    .ant-table .ant-table-thead > tr > th {
        background-color: var(--color-blue-100);
        border: 0;
    }

    .ant-table .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
        display: flex;
    }

    .ant-table-layout-fixed table {
        @include respond(sm) {
            table-layout: auto;
        }
    }

}

.caret {
    transition: .3s all;
}

.sort-icons svg {
    min-width: auto;
    width: 10px;
    height: 6px;
}
