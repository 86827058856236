.sidebar {
    width: var(--sidebar-width);
    position: fixed;
    top: var(--header-height);
    left: 0;
    height: 100vh;
    font-size: 14px;

    &__link {
        border-bottom: 1px solid var(--color-grey-200);
    }
}

.content {
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
    padding: 24px;
}

.user-dropdown .dropdown {
    right: 16px;
    margin-top: -12px;
}

.user-dropdown__trigger {
    border-left: 1px solid #F8F6F705;
}

.accordion {
    font-size: 16px;

    &:not(:first-child) {
        border-top: 1px solid var(--color-border-2);
    }

    &__title {
        padding: 16px 24px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s all;
    }

    &--active {
        background: var(--color-grey-100);
    }

    &__content-wrap {
        background: #f9f9f9;
        overflow: hidden;
        transition: 0.3s all linear;
        max-height: 0;
    }

    &__content {
        padding: 24px;
    }

    // arrow pointing down
    &__arrow {
        transition: 0.4s;
        transform: rotate(90deg);
        width: 14px;
    }

    // -------------------------------
    // active accordion
    // -------------------------------

    &--active &__arrow {
        transform: rotate(-90deg);
    }
}

//.header {
//    z-index: 1030;
//}
