.content-area {
    min-height: calc(100vh - (72px + var(--header-height)));
}

.login-appointment-form {
    .lg\:grid-cols-2 {
        grid-template-columns: repeat(3, minmax(0, 3fr));
    }
}

.test-booking {
    .hr-line .heading-secondary-sm {
        padding-top: 24px;
        border-top: 1px solid var(--color-grey-200);
    }

}

.login {
    margin: auto 0;
    width: 100%;
    overflow: hidden;


    @include respond(sm) {
        width: 100%;
        height: inherit;
        margin: 0;
    }

    &__wrap {
        max-width: 100%;
        width: 1048px;
        margin: auto;
        padding: 64px 0;
        @include respond(sm) {
            padding: 24px;
        }
    }

    &__left {
        background: url("/images/img-log-page.jpg") no-repeat;
        background-size: cover;

        @include respond(sm) {
            display: none;
        }

    }

    &__left-content {
        height: 100%;
        margin: auto;
        background-color: rgba(#111, .8);
        color: #fff;
        display: flex;
        text-align: center;
    }

    &__left p {
        margin-top: 16px;
    }

    &__form {
        padding: 64px 64px 24px;
        @include respond(sm) {
            padding: 24px;
            border-radius: 8px;
        }
    }

    &__form-title {
        padding-bottom: 24px;
    }

}

.container--login {
    max-width: 1048px;
}

.modal--logout .modal-content {
    padding: 80px 0;
}
.pattern .payment-field{
    display:none;
}
