.heading-primary {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.375;
    color: var(--color-grey-1000);

    @include  respond(sm) {
        font-size: 24px;
    }
}

.brand-title{
    color: #fff;

    span{
        color: var(--color-tertiary-100);
    }
}

.heading-primary-md {
    font-size: 24px;
}

.heading-primary-sm {
    font-size: 20px;
    line-height: 1.15;
}

.heading-secondary-sm {
    font-weight: 700;
    color: var(--color-grey-800);
    font-size: 18px;
}
