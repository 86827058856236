.pattern {
    background: url("/images/pattern.png");
}

.sidebar-reg {
    &__list {
        @include respond(lg) {
            display: flex;
            overflow-x: auto;
        }
    }
}

.login-registration {
    .sidebar-reg__list > div:first-child, .sidebar-reg__title {
        display: none;
    }
}

.header-height-deduct {
    min-height: calc(100vh - var(--header-height));
}

.header-footer-height-deduct {
    min-height: calc(100vh - 140px);
}

.registration-welcome {
    height: calc(100vh - 140px);

    @include respond(sm) {
        min-height: calc(100vh - 140px);
        height: auto;
    }
}

.form-header {
    svg {
        width: 26px;
        height: 26px;
    }
}
