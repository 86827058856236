.pattern {
    .ant-table .ant-table-thead > tr > th {
        background-color: var(--color-blue-100);
    }
}

body {
    .ant-checkbox-wrapper {
        position: relative;
        cursor: pointer;

        input[type="checkbox"] {
            display: none;
        }


        &:before,
        &:after {
            content: '';
        }

        &:hover:before {
            border-color: var(--color-tertiary-100);
        }
    }

    .ant-checkbox-wrapper:before {
        display: inline-block;
        vertical-align: middle;
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #ccc;
        width: 16px;
        height: 16px;
        border-radius: 4px;
    }

    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {

        &:before {
            border-color: var(--color-tertiary-100);
            background: var(--color-tertiary-100);
        }

        &:after {
            position: absolute;
            top: 2px;
            left: 6px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 1px 1px 0;
            transform: rotate(
                    43deg
            );
        }
    }

    .ant-checkbox-wrapper + span {
        font-size: 12px;
    }

    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked + span {
        color: var(--color-grey-900);
    }

    .ant-table {
        color: inherit;

        .ant-table-thead > tr > th {
            color: var(--color-grey-900);
        }

        .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
            margin-left: 2px;
        }

        .anticon-caret-up {
            margin-bottom: -1px !important;
        }

        .anticon-caret-up svg, .anticon-caret-down svg {
            width: 14px;
            height: 14px;
        }

        thead th {
            background-color: var(--color-pink-100);
        }

        &-thead > tr > th {
            border: transparent;
        }

        tbody tr td {
            border-bottom: 1px solid var(--color-grey-200);
        }

        tr:hover td {
            background: transparent !important;
        }

        .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
            display: flex;
        }

        .ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort, .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
            background-color: var(--color-grey-200);
        }

        .anticon.on, .ant-table-filter-selected {
            color: var(--color-grey-900) !important;
        }

        &-row-hover td, &-row:hover ,tr.ant-table-row-selected td {
            background-color: var(--color-grey-100) !important;
        }

    }

    .ant-table-layout-fixed table {
        @include respond(sm) {
            // table-layout: auto;
        }
    }

    .ant-table-filter-dropdown {
        min-width: 156px !important;
        border-radius: 8px;
        box-shadow: 8px 8px 8px 0 #6624461A;
        z-index: 1000;
    }

    .ant-table-filter-dropdown-link {
        cursor: pointer;
        transition: .3s all;
        font-size: 12px;
    }

    .ant-table-filter-dropdown-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ant-table-filter-dropdown-link.confirm {
        background: var(--color-tertiary-100);
        color: #fff;
        padding: 1px 12px;
        font-size: 12px;
        border-radius: 4px;

        &:hover {
            background: var(--color-tertiary-200);
        }
    }

    .ant-table-row-expand-icon {
        width: 28px;
        height: 28px;
        color: var(--color-tertiary-100) !important;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid var(--color-tertiary-100);
        @extend .flex-center;
    }


    .ant-table-filter-dropdown-link.clear {
        color: var(--color-grey-400);

        &:hover {
            color: var(--color-grey-300);
        }
    }

    .ant-empty-image {
        display: none;
    }
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    background-color: transparent !important;
}

.anticon-search {
    color: var(--color-grey-400) !important;

    &.ant-table-filter-open {
        color: var(--color-grey-900) !important;
    }
}

.ant-dropdown {

    .ant-input, .ant-btn, .ant-reset {
        font-size: 12px;
    }

    .ant-input {
        @extend .form-control;
        padding: 4px 8px !important;
        border-radius: 4px !important;

        &:focus {
            border-color: var(--color-tertiary-100);
            box-shadow: none;
        }

    }

    .ant-reset {
        color: var(--color-grey-400);
        border: 0;


        &:hover {
            color: var(--color-grey-300);
        }
    }

    .ant-btn-primary {
        display: flex;
        background-color: var(--color-tertiary-100);
        border-radius: 4px;
        border: 0;

        &:hover {
            background-color: var(--color-tertiary-200);
        }
    }

    .ant-dropdown-menu-item:hover {
        background: transparent;

        label:before {
            border-color: var(--color-tertiary-100);
        }

        span {
            color: var(--color-grey-900)
        }
    }

    .ant-dropdown-menu-title-content {
        color: var(--color-grey-400);


    }
}


.patient-dashboard {

    .ant-table-filter-dropdown-link.confirm {
        background: var(--color-secondary-100) !important;

        &:hover {
            background: var(--color-secondary-200) !important;
        }
    }

    .ant-dropdown {

        .ant-dropdown-menu-item:hover {
            background: transparent;

            label:before {
                border-color: var(--color-secondary-100);
            }
        }

        .ant-dropdown-menu-title-content {

            label.ant-checkbox-wrapper-checked {

                &:before {
                    border-color: var(--color-secondary-100) !important;
                    background: var(--color-secondary-100) !important;
                }

                &:after {
                    position: absolute;
                    top: 2px;
                    left: 6px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 1px 1px 0;
                    transform: rotate(
                            43deg
                    );
                }
            }
        }
    }
}


.pending-test-table table tbody tr td:first-child {
    padding-left: 40px;
}

.modal-content {
    td, th {
        padding: 16px !important;
    }
}

body .ant-table-pagination.ant-pagination{
    margin: 24px 16px 0;
}


.anticon.anticon-delete {
    color: red;
}
